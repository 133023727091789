<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CTabs fade addTabsWrapperClasses="tab-info">
          <CTab :title="$t('Product/Detail.GiftInfo')" active>
            <CRow>
              <CCol md="8">
                <CInput
                  v-if="Action === 'Edit'"
                  :label="$t('Product/Detail.SeriesNum')"
                  v-model="Data.SeriesNum"
                  horizontal
                  disabled
                />
                <CInput
                  :label="$t('Product/Detail.Name')"
                  v-model="Data.Name"
                  horizontal
                />
                <CInput
                  :label="$t('Product/Detail.HeaderText')"
                  v-model="Data.HeaderText"
                  horizontal
                />
                <CInput
                  :label="$t('Product/Detail.Weight')"
                  v-model="Data.Variable.Weight"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>KG</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Length')"
                  v-model="Data.Variable.Length"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Width')"
                  v-model="Data.Variable.Width"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CInput
                  :label="$t('Product/Detail.Height')"
                  v-model="Data.Variable.Height"
                  type="number"
                  min="0.00" step="0.01"
                  horizontal>
                  <template #append-content>
                    <span>CM</span>
                  </template>
                </CInput>
                <CSelect horizontal :label="$t('Product/Detail.Temperature')" :options="TemperatureOptions"
                         v-model="Data.Variable.Temperature" :value.sync="Data.Variable.Temperature"
                         :placeholder="$t('Global.PleaseSelect')"
                         :description="$t('Product/Detail.TemperatureDescription')"
                />
              </CCol>
              <CCol md="4">
                <CInput
                  :label="$t('Product/Detail.CustomSeriesNum')"
                  v-model="Data.CustomSeriesNum"
                  horizontal
                />
                <CInput
                  :label="$t('Product/Detail.Model')"
                  v-model="Data.Model"
                  horizontal
                />
                <CInput
                  :label="$t('Product/Detail.Sort')"
                  v-model="Data.Sort"
                  type="number"
                  horizontal
                />
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/Detail.Status') }}
                  </CCol>
                  <CCol sm="9">
                    <CSwitch color="success" :checked.sync="ProductStatus"/>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CTab>
          <CTab :title="$t('Product/Detail.GiftImages')">
            <h5>{{ $t('Product/Detail.Cover') }}</h5>
            <hr>
            <CRow class="no-gutters mx-n1 mb-3">
              <CCol v-if="Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                <div class="position-relative CarouselItem">
                  <img v-lazy="Data.Cover" class="img-fluid" />
                  <div class="CarouselTools">
                    <CButton color="info" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                      <CIcon name="cil-pencil" />
                    </CButton>
                  </div>
                </div>
              </CCol>
              <CCol v-if="!Data.Cover" xl="2" lg="3" md="4" sm="6" class="px-1 mb-2">
                <div class="bg-light h-100 d-flex justify-content-center align-content-center CarouselItem">
                  <CIcon name="cil-image-plus" class="c-icon-custom-size AddImages" />
                  <div class="CarouselTools">
                    <CButton color="success" size="sm" class="mx-1" @click="OpenMediaStore('Cover', false)">
                      <CIcon name="cil-image-plus" /> {{ $t('Global.Add') }}
                    </CButton>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CTab>
          <CTab :title="$t('Product/Detail.GiftContent')">
            <CRow>
              <CCol md="8" lg="9">
                <Editor api-key="4q91812x421qc6po6evarups2m6zj0hj92uulv0nvikgl784" :init="TinyMCE" v-model="CurrentContent" />
                <template v-if="Debug">
                  <hr>
                  <div class="bg-light p-3">
                    <code v-if="EnableResponseContent && NowChooseDevice === 'Web'">{{ Data.ResponseData.Web.Content }}</code>
                    <code v-if="EnableResponseContent && NowChooseDevice === 'Mobile'">{{ Data.ResponseData.Mobile.Content }}</code>
                    <code v-if="!EnableResponseContent">{{ Data.Content }}</code>
                  </div>
                </template>
              </CCol>
              <CCol md="4" lg="3">
                <CRow form class="form-group">
                  <CCol tag="label" sm="4" class="col-form-label">
                    Debug
                  </CCol>
                  <CCol sm="8">
                    <CSwitch color="success" :checked.sync="Debug"/>
                  </CCol>
                </CRow>
                <CRow form class="form-group">
                  <CCol tag="label" sm="4" class="col-form-label">
                    {{ $t('Product/Detail.EnableResponseContent') }}
                  </CCol>
                  <CCol sm="8">
                    <CSwitch color="success" :checked.sync="EnableResponseContent"/>
                  </CCol>
                </CRow>
                <template v-if="EnableResponseContent">
                  <CButton :color="(NowChooseDevice === 'Web' ? 'success' : 'light')" variant type="button" block @click="NowChooseDevice = 'Web'">
                    <CIcon name="cil-monitor" class="mr-1" /> {{ $t('Product/Detail.Web') }}
                  </CButton>
                  <CButton :color="(NowChooseDevice === 'Mobile' ? 'success' : 'light')" type="button" block @click="NowChooseDevice = 'Mobile'">
                    <CIcon name="cil-mobile" class="mr-1" /> {{ $t('Product/Detail.Mobile') }}
                  </CButton>
                </template>
              </CCol>
            </CRow>
          </CTab>
          <CTab :title="$t('Product/Detail.Stock')">
            <CAlert color="info">
              <CIcon name="cil-bell" /> 需要設定SKU才能正常使用庫存，請前往「<b><a href="/products/stock/list" target="_blank" class="text-info">商品管理 > 庫存</a></b>」以新增庫存單位。
            </CAlert>
            <CRow>
              <CCol md="8">
                <CRow form class="form-group">
                  <CCol tag="label" sm="3" class="col-form-label">
                    {{ $t('Product/Detail.EnableStock') }}
                  </CCol>
                  <CCol sm="9">
                    <CSwitch color="success" :checked.sync="EnableStock"/>
                  </CCol>
                </CRow>
                <template v-if="EnableStock">
                  <CInput
                    v-model="Data.Stock.SKU"
                    horizontal
                  >
                    <template #label>
                      <label class="col-form-label col-sm-3">
                        {{ $t('Product/Detail.Stock/SKU') }} <i v-c-tooltip="{content: $t('Product/Detail.Stock/SKUDescription'),placement: 'bottom'}" class="fas fa-question-circle text-info ml-1" />
                      </label>
                    </template>
                  </CInput>
                  <CInput
                    :label="$t('Product/Detail.Stock/RealStock')"
                    v-model="Data.Stock.RealStock"
                    type="number"
                    :disabled="true"
                    horizontal>
                  </CInput>
                  <CInput
                    :label="$t('Product/Detail.Stock/SafeStock')"
                    v-model="Data.Stock.SafeStock"
                    :disabled="true"
                    type="number"
                    horizontal>
                  </CInput>
                  <CInput
                    :label="$t('Product/Detail.Stock/MaxStock')"
                    v-model="Data.Stock.MaxStock"
                    type="number"
                    horizontal>
                  </CInput>
                </template>
              </CCol>
              <CCol md="4"></CCol>
            </CRow>
          </CTab>
        </CTabs>
        <CCard class="rounded-0">
          <CCardBody class="py-2 px-3">
            <div class="card-header-actions d-flex">
              <CButton v-if="Action === 'Edit'" color="info" type="button" class="mr-2" @click="$router.push('/offerItems/gift/add')">
                {{ $t('Global.ContinueAdd') }}
              </CButton>
              <CButton color="success" type="submit">
                {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
        <CElementCover v-if="(Loading === true)" :opacity="0.75">
          <CSpinner color="success"/>
        </CElementCover>
      </CForm>
    </CContainer>
    <MediaStore :Config="MediaStoreConfig" ref="MediaStore" @OK="SetImages(ChooseImageType)"></MediaStore>
    <MediaMeta :Config="MediaMetaConfig" @OK="SetMeta"></MediaMeta>
    <input id="tinymce-image-input" type="text" class="d-none">
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯贈品"
  }
}
</route>

<script>
import dayjs from 'dayjs'
import DataSchema from '@/schema/product/detail'
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'ProductDetail',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    MediaMeta: () => import('@/components/public/MediaMeta'),
    Editor
  },
  data () {
    return {
      Action: 'Edit',
      Data: {}, //主資料

      ProductStatus: true, //格式化後的商品狀態
      EnableStock: false, //格式化後的商品庫存啟用開關
      EnableResponseContent: false, //格式化後的響應式商品內容開關
      Debug: false,
      CategoryList: [],
      VariableList: [],
      NowChooseDevice: 'Web',
      NowChooseLanguage: '',
      Loading: false,
      Drag: false,
      ChooseImageType: '',
      ChooseImageIndex: false,
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false,
        ChooseImageType: '',
      },
      MediaMetaConfig: {
        Display: false,
        ImageMeta: {}
      },
      TinyMCE: {
        ...TinyMCESetting,
        file_picker_callback: (callback) => {
          this.OpenMediaStore('Editor', false)
          const input = document.getElementById('tinymce-image-input')
          input.addEventListener('change', (event) => {
            callback(event.target.value, {title: ''})
          })
        },
      },

      TypeList: ['boolean', 'string', 'number', 'object', 'array'],
      VariableNoItems: {
        noResults: this.$t('Product/Detail.NoVariableItems'),
        noItems: this.$t('Product/Detail.NoVariableItems')
      }
    }
  },
  computed: {
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    TemperatureOptions() {
      return Object.keys(this.$t('Logistics.TemperatureOptions')).map(value => {
        return {
          label: this.$t('Logistics.TemperatureOptions.' + value),
          value
        }
      })
    },
    CurrentContent: {
      get () {
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                return this.Data.ResponseData[this.NowChooseDevice].Content
              }
          }
        }
        return this.Data.Content
      },
      set (value) {
        if (this.EnableResponseContent) {
          switch (this.NowChooseDevice) {
            case 'Web':
            case 'Mobile':
              if (this.Data.ResponseData[this.NowChooseDevice]) {
                this.Data.ResponseData[this.NowChooseDevice].Content = value
              }
          }
        } else {
          this.Data.Content = value
        }
      }
    }
  },
  created() {
    this.$set(this.$data, 'Data', Object.assign({}, DataSchema))
    this.Action = (this.$route.name === 'products-gift-detail-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Functions = []
      if (this.Action === 'Edit') Functions.push(this.GetData())
      return Promise.all(Functions).then(() => {
        //詳細資料處理
        if (this.Action === 'Edit') this.HandleDetailData()
        return true
      }).catch((err) => {
        console.error(err)
        throw err
      })
    },
    Save() {
      //拆分動作
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/offerItems/giftDetail/' + result.data.SeriesNum)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Product/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/add',
        method: 'post',
        data: {
          ...this.Data,
          Categories: ['Gift'],
          Taxonomy: 'Gift',
          Status: this.ProductStatus ? 1 : 0,
          EnableStock: this.EnableStock ? 1 : 0,
          EnableResponseContent: this.EnableResponseContent ? 1 : 0,
          OnlineTime: 0,
          OfflineTime: 2147483647,
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Product/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      const updateData = {
        ...this.Data,
        Status: this.ProductStatus ? 1 : 0,
        EnableStock: this.EnableStock ? 1 : 0,
        EnableResponseContent: this.EnableResponseContent ? 1 : 0,
        OnlineTime: dayjs(this.Data.OnlineTime).unix(),
        OfflineTime: dayjs(this.Data.OfflineTime).unix()
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/edit',
        method: 'post',
        data: {
          seriesNum: updateData.SeriesNum,
          taxonomy: 'Gift',
          updateData
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Product/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/detail',
        method: 'get',
        params: {
          seriesNum: this.$route.params.id,
          taxonomy: 'Gift'
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.detail
        if (!this.Data.Variable) this.$set(this.Data, 'Variable', {})
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    HandleDetailData() {
      if (this.Data.Variable) {
        this.VariableList = Object.keys(this.Data.Variable).map((data) => {
          let Value,
              Type = typeof this.Data.Variable[data]
          switch (typeof this.Data.Variable[data]) {
            case 'boolean':
            case 'string':
            case 'undefined':
            case 'number':
              Value = this.Data.Variable[data]
              break
            case 'object':
              Value = JSON.stringify(this.Data.Variable[data])
              if (Array.isArray(this.Data.Variable[data])) {
                Type = 'array'
              }
              break
          }
          return {
            Index: data,
            Value,
            Type
          }
        })
      }

      this.ProductStatus = (this.Data.Status === 1)
      this.EnableOption = (this.Data.EnableOption === 1)
      this.EnableStock = (this.Data.EnableStock === 1)
      this.HiddenAmount = (this.Data.HiddenAmount === 1)
      this.EnableResponseContent = (this.Data.EnableResponseContent === 1)
    },
    HandleVariable() {
      this.VariableList.forEach((data) => {
        this.Data.Variable[data.Index] = data.Value
      })
    },
    OpenMediaStore(type = 'Carousel', multiple = true, index = false) {
      this.ChooseImageType = this.MediaStoreConfig.ChooseImageType = type
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = multiple
      if (index !== false) {
        this.ChooseImageIndex = index
      }
    },
    async SetImages (type = 'Carousel') {
      const storageInstance = this.$store.state.userProjectApp.storage()
      switch (type) {
        case 'Cover':
          this.SetSingleImage('Cover')
          break
        case 'Editor':
          document.getElementById('tinymce-image-input').value = await this.ResolveImageURL()
          document.getElementById('tinymce-image-input').dispatchEvent(new Event('change'));
          break
        default:
          if (typeof this.Data[type] === 'undefined') {
            this.$set(this.Data, type, [])
          }
          this.ChooseImageType = ''
            switch (typeof this.MediaStoreConfig.ChooseImageList) {
              case 'string':
                if (this.$store.state.user.permission.StaticDomain) {
                  this.$set(this.Data[type], this.ChooseImageIndex, {
                    URL: this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList,
                    Alt: ''
                  })
                } else {
                  storageInstance.ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
                    this.$set(this.Data[type], this.ChooseImageIndex, {URL, Alt: ''})
                  })
                }
                break
              case 'object':
                if (Array.isArray(this.MediaStoreConfig.ChooseImageList)) {
                  this.MediaStoreConfig.ChooseImageList.forEach(imgPath => {
                    if (this.$store.state.user.permission.StaticDomain) {
                      this.Data[type].push({
                        URL: this.$store.state.user.permission.StaticDomain + imgPath,
                        Alt: ''
                      })
                    } else {
                      storageInstance.ref().child(imgPath).getDownloadURL().then((URL) => {
                        this.Data[type].push({
                          URL,
                          Alt: ''
                        })
                      })
                    }
                  })
                }
                break
            }
          break
      }
    },
    SetSingleImage(type, index) {
      if (this.$store.state.user.permission.StaticDomain) {
        if (index) this.Data[type][index] = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
        else this.$set(this.Data, type, this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      } else {
        this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
          if (index) this.Data[type][index] = URL
          else this.$set(this.Data, type, URL)
        })
      }
    },
    ResolveImageURL () {
      if (this.$store.state.user.permission.StaticDomain) {
        return Promise.resolve(this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList)
      }
      return this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL().then((URL) => {
        return URL
      })
    },
    OpenMediaMeta(type = 'Carousel', index = false) {
      if (index === false) {
        return false
      }
      this.MediaMetaConfig.Display = !this.MediaMetaConfig.Display
      this.MediaMetaConfig.ImageMeta = this.Data[type][index]
      this.ChooseImageType = type
      this.ChooseImageIndex = index
    },
    SetMeta() {
      this.MediaMetaConfig.ImageMeta = {}
      this.ChooseImageType = ''
      this.ChooseImageIndex = false
      this.MediaMetaConfig.Display = false
    },
    RemoveImage(type = '', index) {
      this.Data[type].splice(index, 1)
    }
  }
}
</script>
